import GhostContentAPI from "@tryghost/content-api"

// Create API instance with site credentials

// const api = new GhostContentAPI({
//   url: "https://blog.eggallday.com",
//   key: "662678caec3be6ec3ba3d8e6f8",
//   version: "v3",
// })

const api = new GhostContentAPI({
  url: "https://blog.eggallday.com",
  key: "662678caec3be6ec3ba3d8e6f8",
  version: "v3",
})

export async function getPosts() {
  return await api.posts
    .browse({
      limit: "all",
      include: "tags,authors",
    })
    .catch((err) => {
      console.error(err)
    })
}

export async function getSinglePost(postSlug) {
  return await api.posts
    .read({
      slug: postSlug,
    })
    .catch((err) => {
      console.error(err)
    })
}

export async function getAllPosts() {
  return await api.posts
    .browse({
      limit: "all",
      include: "tags,authors",
    })
    .catch((err) => {
      console.error(err)
    })
}

export async function getPostbyTag(tag) {
  return await api.posts
    .browse({
      limit: "all",
      filter: tag,
      include: "tags,authors",
    })
    .catch((err) => {
      console.error(err)
    })
}

export async function getPostbyAuthor(author) {
  return await api.posts
    .browse({
      limit: "all",
      filter: `authors:${author}`,
      include: "tags,authors",
    })
    .catch((err) => {
      console.error(err)
    })
}
